<template>
    <div class="shows content-wide">
        <div class="content-img-container">
            <img src="@/assets/img/6.jpeg" :alt="$t('shows.title')" />
        </div>

        <h1 class="big-title">{{ $t('shows.title') }}</h1>

        <Transition name="fade">
            <div v-show="showShows" class="shows-container">
                <div class="upcoming">
                    <h2>{{ $t('shows.upcomingShows') }}</h2>
                    <ShowButton v-for="gig in upcomingGigs" :key="gig.name + Math.random()"
                        :date="new Date(gig.actualDate.seconds * 1000)" :title="gig.name ? gig.name : gig.location"
                        :link="gig.link" :darkMode="true" />
                </div>
                <div class="archive">
                    <h2>{{ $t('shows.archive') }} ({{ this.allPastShows }})</h2>
                    <div v-for="year in sortedYears" :key="year">
                        <div v-if="pastGigsSorted[year] && pastGigsSorted[year].length > 0">
                            <h3>{{ year }}</h3>
                            <div v-for="gig in pastGigsSorted[year]" :key="gig.name + Math.random()"
                                class="archive-gig">
                                <div>{{ toLocalDate(new Date(gig.actualDate.seconds * 1000)) }}</div>
                                <div>{{ gig.name ? gig.name : gig.location }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script src="./Shows.ts"></script>
<style lang="scss" scoped>
@import './Shows.scss';
</style>
